<template>
    <div>
        <v-tabs 
            v-model="tabs" 
            color="accent"
            fixed-tabs 
            icons-and-text
        >
            <v-tab key="create">Créer <v-icon>mdi-account-plus</v-icon></v-tab>
            <v-tab key="import">Importer <v-icon>mdi-import</v-icon></v-tab>
            <v-tab key="merge">Fusionner <v-icon>mdi-call-merge</v-icon></v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs" touchless>
            <v-tab-item key="create">
                <m-users-admin-dialog-tabs-create @close-dialog="$emit('close-dialog')" />
            </v-tab-item>
            <v-tab-item key="import">
                <m-users-admin-dialog-tabs-import @close-dialog="$emit('close-dialog')" />
            </v-tab-item>
            <v-tab-item key="merge">
                <v-card flat>
                    <v-card-text>
                        Cette fonction est en cours de développement...
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import mUsersAdminDialogTabsCreate from './adminDialogTabsCreate.vue';
import mUsersAdminDialogTabsImport from './adminDialogTabsImport.vue';

export default {
    name: 'mUsersAdminDialogTabs',
    components: { 
        mUsersAdminDialogTabsCreate,
        mUsersAdminDialogTabsImport,
    },
    data() { return {
        tabs: null,
    }},
}
</script>

<style>

</style>