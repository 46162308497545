<template>
    <v-card>
        <!-- PHASE 1 -->
        <v-form v-show="!done"
            ref="formCreate"
            @submit.prevent="submitForm"
            :disabled="sendLoading"
        >
            <v-card-text>
                <v-select
                    v-model="form.nameTitle"
                    label="Salutation"
                    :rules="[rules.req]"
                    :items="getNameTitlesArr"
                    item-value="value"
                    item-text="long"
                    outlined
                />
                <v-text-field
                    v-model="form.nameLast"
                    label="Nom"
                    :rules="[rules.req]"
                    outlined
                />
                <v-text-field
                    v-model="form.nameFirst"
                    label="Prénom"
                    :rules="[rules.req]"
                    outlined
                />
                <v-text-field
                    v-model="form.email1"
                    label="Adresse mail principale"
                    type="email"
                    :rules="[rules.req, rules.email]"
                    outlined
                />
                <v-text-field
                    v-model="form.phoneMobile"
                    label="Numéro de portable"
                    type="tel"
                    hint="Format +410213456789"
                    prefix="+"
                    :rules="[rules.req, rules.phone]"
                    outlined
                />
                <v-text-field
                    v-model="form.nip"
                    label="NIP"
                    :rules="[rules.req, rules.alphaNum]"
                    hint="Attention, le NIP ne pourra pas être modifié"
                    outlined
                />
                <v-select
                    v-model="form.rank"
                    label="Grade"
                    :rules="[rules.req]"
                    :items="getRanksArr"
                    item-value="id"
                    item-text="long"
                    outlined
                />
                <v-select
                    v-model="form.section"
                    label="Détachement"
                    :rules="[rules.req]"
                    :items="['DAP', 'DPS', 'JSP']"
                    outlined
                />
                <v-text-field
                    v-model="form.password"
                    label="Mot de passe (optionnel)"
                    hint="Si vide, un mot de passe aléatoire sera crée"
                    outlined
                />
                <v-checkbox
                    v-model="makeAccount"
                    label="Créer compte"
                    hint="Créer un compte pour accéder à focus"
                    persistent-hint
                />
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                <v-btn color="secondary" @click="$emit('close-dialog')">
                    <span v-if="big">Annuler</span>
                    <v-icon :right="big">mdi-close</v-icon>
                </v-btn>
                <v-btn
                    type="submit"
                    :color="button[btnStatus].color"
                    :loading="sendLoading"
                    :disabled="sendLoading"
                >
                    <span v-if="big">{{ button[btnStatus].text }}</span>
                    <v-icon :right="big">{{ button[btnStatus].icon }}</v-icon>
                </v-btn>
            </v-card-actions>
        </v-form>

        <!-- PHASE 2 -->
        <v-card-text v-show="done">
            <v-alert type="success" text>
                <p>
                    L'utilsateur a été crée avec succès.
                </p>
                <div v-if="pw">
                    <p>
                        Notez le mot de passe ci-dessous. Il est consseillé de le changer lors de la première connexion.
                    </p>
                    <v-chip label tag="samp">
                        {{ pw }}
                        <v-icon
                            right 
                            small 
                            :disabled="clipboardLoading"
                            @click="toClipboard(pw)"
                        >
                            {{ clipboardSuccess ? 'mdi-check' : 'mdi-content-copy'}}
                        </v-icon>
                    </v-chip>
                </div>
            </v-alert>
            <v-btn
                color="success"
                block
                @click="processDone()"
            >
                Confirmer
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import { RANKS, NAME_TITLES } from '@/constants/';
import { REQUIRED, TELEPHONE, EMAIL } from '@/constants/rules';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
const functions = getFunctions(getApp(), 'europe-west1');
const createUser = httpsCallable(functions, 'user-createUser');

export default {
    name: 'mUsersAdminDialogTabsCreate',
    data() { return {
        form: {},
        makeAccount: true,
        pw: '',
        done: false,
        rules: {
            req: value => REQUIRED(value),
            phone: value => TELEPHONE(value),
            alphaNum: value => /^\w*$/.test(value) || 'Format invalide',
            email: value => EMAIL(value),
        },
        sendLoading: false,
        btnStatus: 'default',
        button: {
            default: { color: 'primary', icon: 'mdi-content-save', text: 'Enregistrer' },
            success: { color: 'success', icon: 'mdi-check', text: 'Enregistré' },
            failure: { color: 'error', icon: 'mdi-alert', text: 'Echec' },
        },
        clipboardLoading: false,
        clipboardSuccess: false,
    }},
    computed: {
        getRanksArr() {
            return Object.values(RANKS);
        },
        getNameTitlesArr() {
            return Object.values(NAME_TITLES);
        },
        big() {
            return !this.$vuetify.breakpoint.xsOnly;
        },
    },
    methods: {
        submitForm() {
            if (this.$refs.formCreate.validate()) {
                this.sendLoading = true;
                createUser({
                    user: this.form,
                    makeAccount: this.makeAccount,
                }).then(res => {
                    this.btnStatus = 'success';
                    this.pw = res.data.password;
                    this.done = true;
                }).catch(error => {
                    this.btnStatus = 'failure';
                    this.$store.commit('setError', { type: 'functions', code: error.code, message: error.message });
                }).finally(() => {
                    this.sendLoading = false;
                    setTimeout(() => {this.btnStatus = 'default'}, 3000);
                });
            }
        },
        toClipboard(data) {
            this.clipboardLoading = true;
            navigator.clipboard.writeText(String(data)).then(() => {
                this.clipboardSuccess = true;
                setTimeout(() => {
                    this.clipboardSuccess = false;
                }, 2000);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.clipboardLoading = false;
            });
        },
        processDone() {
            this.$emit('close-dialog');
            this.$refs.formCreate.reset()
            this.makeAccount = true;
            this.pw = '';
            this.done = false;
        },
    }
}
</script>

<style>

</style>