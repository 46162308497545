<template>
    <v-simple-table fixed-header height="300" dense
        :dark="$vuetify.theme.dark"
        :light="!$vuetify.theme.dark">
        <thead>
            <tr>
                <th>Nom, prénom</th>
                <th>NIP</th>
                <th>Importé</th>
                <th>Etat</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(p, i) in people" :key="'new-'+i">
                <td>{{ getName(p) }}</td>
                <td>{{ p.nip }}</td>
                <td>
                    <v-simple-checkbox :ripple="false" v-model="p.success" disabled/>
                </td>
                <td>{{ p.result }}</td>
            </tr>
        </tbody>
    </v-simple-table>
</template>

<script>
export default {
    name: 'mUsersAdminDialogTabsImportCreated',
    props: {
        people: {type: Array, required: true}
    },
    methods: {
        getName(p) {
            const nameLast = p.success ? p.name.last : p.nameLast;
            const nameFirst = p.success ? p.name.first : p.nameFirst;
            return `${nameLast} ${nameFirst}`;
        }
    }
}
</script>

<style>

</style>