<template>
    <v-dialog 
        v-model="dialog"
        max-width="500px"
    >
        <template #activator="{ on, attrs }">
            <v-btn
                color="accent"
                v-bind="attrs"
                v-on="on"
            >
                Admin
                <v-icon right>mdi-shield-crown</v-icon>
            </v-btn>
        </template>

        <v-card>
            <m-users-admin-dialog-tabs @close-dialog="dialog = false" />
        </v-card>
    </v-dialog>
</template>

<script>
import mUsersAdminDialogTabs from './adminDialogTabs.vue';

export default {
    name: 'mUsersAdminDialog',
    components: { mUsersAdminDialogTabs },
    data() { return {
        dialog: false,
    }},
}
</script>

<style>

</style>