<template>
    <v-simple-table fixed-header height="300" dense>
        <thead>
            <tr>
                <th>Nom, prénom</th>
                <th>NIP</th>
                <th>Détach.</th>
                <th>Erreur</th>
                <th>Import</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(p, i) in people" :key="'new-'+i">
                <td>{{ p.nameLast }} {{ p.nameFirst }}</td>
                <td>{{ p.nip }}</td>
                <td>{{ p.section }}</td>
                <td class="error--text">{{ p.error || '👍' }}</td>
                <td>
                    <v-simple-checkbox :ripple="false" v-model="p.import" disabled/>
                </td>
            </tr>
        </tbody>
    </v-simple-table>
</template>

<script>
export default {
    name: 'mUsersAdminDialogTabsImportFutures',
    props: {
        people: {type: Array, required: true}
    }
}
</script>

<style>

</style>