<template>
    <v-dialog
    v-model="dialog">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="accent" v-bind="attrs" v-on="on">
                Colonnes <v-icon right>mdi-table-edit</v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-card-title>
                Sélection de colonnes
            </v-card-title>

            <v-divider class="mb-2" />

            <v-card-text>
                <v-select
                    :value="value"
                    label="Colonnes"
                    :items="availHeaders"
                    multiple
                    chips
                    deletable-chips
                    clearable
                    @change="$emit('input', $event)"
                />
                
                <v-divider class="my-2" />

                <span class="text-h6">Modèles</span>
                <v-row>
                    <v-col cols="auto">
                        <v-btn color="primary"
                        @click="$emit('input', defaultCols)">Par défaut</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn color="primary"
                        @click="$emit('input', [0,1,2,3,7,8,9])">Rôles et fonctions</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn color="primary"
                        @click="$emit('input', [0,1,2,3,7,8,9])">Formation DPS</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn
                color="secondary"
                @click="dialog = false">
                    Fermer
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'mUsersSelectCols',
    props: ['value', 'availHeaders'],
    data() { return {
        dialog: false,
        defaultCols: [],
    }},
    created() {
        this.defaultCols = [...this.value];
    },
}
</script>

<style>

</style>