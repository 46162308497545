<template>
    <div>
        <v-file-input
            v-model="file"
            label="Fichier CSV"
            accept=".csv"
            :loading="loading"
            :error="error.active"
            class="mb-2"
            @change="parse"
        />
        <v-alert v-if="!file" type="info">
            Veuillez séléctionner un fichier pour commencer
        </v-alert>

        <div class="text-center" v-if="loading">
            <v-progress-circular
                indeterminate
            />
        </div>
        
        <v-alert v-if="error.active" type="error">
            {{ error.text }}
        </v-alert>
    </div>
</template>

<script>
import Papa from 'papaparse';
import { RANKS } from '@/constants';
import { TELEPHONE, EMAIL } from '@/constants/rules';

const nameTitles = {
    'Madame': 'mrs',
    'Monsieur': 'mr',
    'Mademoiselle': 'ms',
};
const requiredKeys = [
    'NIP', 'Grade', 'Détachement',
    'Nom', 'Prénom', 'Politesse',
    'Email', 'Natel'
];

export default {
    name: 'mUsersAdminDialogTabsImportField',
    data() { return {
        file: null,
        loading: false,
        error: { active: false, text: '' },
    }},
    methods: {
        parse(e) {
            this.parseErrorText = '';
            let people = [];
            this.error = { active: false, text: '' };
            
            if (e) {
                this.loading = 'accent';
                Papa.parse(e, {
                    header: true,
                    complete: result => {
                        const defaultImport = !result.meta.fields.includes('Importer');
                        if (requiredKeys.every(item => result.meta.fields.includes(item))) {
                            const last = result.data[result.data.length-1];
                            if (last[Object.keys(last)[0]] == '')
                                result.data.pop();

                            result.data.forEach(p => {
                                let error = [];
                                // Convert fields
                                if (/^07\d \d{3} \d{2} \d{2}$/.test(p['Natel']))
                                    p['Natel'] = '41' + p['Natel'].substring(1).replaceAll(' ', '');

                                p['Grade'] = p['Grade'].replaceAll(' ', '').toLowerCase();
                                    
                                // Verify fields
                                if (!Object.keys(RANKS).includes(p['Grade']))
                                    error.push('Grade');
                                
                                if (TELEPHONE(p['Natel']) !== true)
                                    error.push('Natel');

                                if (EMAIL(p['Email']) !== true)
                                    error.push('Email');

                                people.push({
                                    nip: p['NIP'],
                                    rank: p['Grade'],
                                    section: p['Détachement'],
                                    nameLast: p['Nom'],
                                    nameFirst: p['Prénom'],
                                    nameTitle: nameTitles[p['Politesse']],
                                    email1: p['Email'],
                                    phoneMobile: p['Natel'],
                                    import: error.length ? false : Boolean(p['Importer'] ?? defaultImport),
                                    error: error.join(', '),
                                    // optional
                                    exGroupDps: p['Groupe ex DPS'],
                                });
                            });
                            this.loading = false;
                        }
                        else {
                            this.error.active = true;
                            this.error.text = 'Il manque des colonnes dans votre fichier';
                        }
                    },
                });
            }
            this.$emit('change', people);
            this.loading = false;
        },
    }
}
</script>

<style>

</style>