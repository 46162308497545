<template>
    <v-data-table
        :loading="loading"
        loading-text="Chargement..."
        :headers="headers"
        :items="users"
        :sort-by="['name.last', 'name.first']"
        :multi-sort="true"
        :items-per-page="20"
        locale="fr-CH"
        no-data-text="Aucun résultat"
        no-results-text="Aucun résultat correspondant aux filtres"
        :header-props="{
            'sort-by-text': 'Trier par',
        }"
        :footer-props="{
            'items-per-page-all-text': 'Tous',
            'items-per-page-text': 'Lignes par page',
            'items-per-page-options': [5, 10, 20, 50, -1],
        }"
        @click:row="rowClick"
    >
        <template #[`item.rank`]="{value}">
            {{ getRank(value)['med'] }}.
        </template>
        <template #[`item.contact.email[0]`]="{value}">
            <a :href="`mailto:${value}`" class="table-btn">{{ value }}</a>
        </template>
        <template #[`item.contact.phone.mobile`]="{value}">
            <a v-if="!!value" :href="`tel:+${value}`" class="table-btn">+{{ value }}</a>
        </template>

        <template v-for="role in roles" #[`item.roles.${role}`]="{value}">
            <v-icon v-if="value" :key="role">mdi-check</v-icon>
        </template>

        <template #[`item.actions`]="{value}">
            <v-btn
                :href="`mailto:${value.email}`"
                icon
                @click.stop
            >
                <v-icon>mdi-email</v-icon>
            </v-btn>
            
            <v-btn
                :href="`tel:+${value.mobile}`"
                :disabled="!value.mobile"
                icon
                @click.stop
            >
                <v-icon>mdi-phone</v-icon>
            </v-btn>

            <v-btn
                :href="`sms:+${value.mobile}`"
                :disabled="!value.mobile"
                icon
                @click.stop
            >
                <v-icon>mdi-message-processing</v-icon>
            </v-btn>

            <v-btn
                :href="`https://wa.me/${value.mobile}`"
                target="_blank"
                :disabled="!value.mobile"
                icon
                @click.stop
            >
                <v-icon>mdi-whatsapp</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
import { RANKS, ROLES } from '@/constants';
import { collection, getFirestore, onSnapshot, query, where } from 'firebase/firestore';
const db = getFirestore();
let dbUnsub;

export default {
    name: 'mUsersDataTable',
    props: ['headers', 'filters'],
    data: () => ({
        users: [],
        loading: 'accent',
        roles: Object.keys(ROLES),
    }),
    created() {
        const q = query(collection(db, 'users'), where('status', '==', 'active'))
        dbUnsub = onSnapshot(q, snapshot => {
            let dbUsers = [];

            snapshot.forEach(doc => {
                let data = doc.data();
                data.uid = doc.id;

                const mobile = doc.get('contact.phone.mobile') || '';
                data.actions = {
                    mobile: mobile,
                    email:  doc.data().contact.email[0],
                    uid:    doc.id,
                };
                dbUsers.push(data);
            });

            if (dbUsers.length > 1) {
                this.users = dbUsers;
                this.loading = false;
            }
        });
    },
    updated() {
        // console.log(this.users);
    },
    destroyed() {
        if (dbUnsub)
            dbUnsub();
    },
    methods: {
        getRank(rankId) {
            if (RANKS[rankId])
                return RANKS[rankId];
        },
        rowClick(row) {
            this.$router.push({ name: 'User profile', params: {id: row.uid} })
        },
    },
}
</script>

<style>
.table-btn {
    text-decoration: none;
}
.table-btn:hover {
    text-decoration: underline;
}
</style>