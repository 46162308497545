<template>
    <v-container>
        <v-row align="center">
            <v-col>
                <m-users-select-cols v-model="headerIds" :availHeaders="headerNames" />
            </v-col>
            <v-col cols="auto" v-if="canEdit" >
                <m-users-admin-dialog/>
            </v-col>
        </v-row>
        <v-divider class="my-5" />
        <m-users-data-table :headers="getHeaders" />
    </v-container>
</template>

<script>
import { RANKS, ROLES } from '@/constants';
import mUsersDataTable from '../components/users/dataTable.vue';
import mUsersSelectCols from '../components/users/selectCols.vue';
import mUsersAdminDialog from '../components/users/adminDialog.vue';

export default {
    name: 'mUsers',
    components: {
        mUsersDataTable,
        mUsersSelectCols,
        mUsersAdminDialog,
    },
    data() { return {
        filters: [],
        headerIds: [0, 1, 2, 3, 4, 9],
        headerNames: [],
        availHeaders: [
            { text: 'Grade', value: 'rank', //0
                sort: (a, b) => {
                    const rankA = RANKS[a] || 0;
                    const rankB = RANKS[b] || 0;
                    return rankA.value - rankB.value;
                }
            },
            { text: 'Nom', value: 'name.last', }, //1
            { text: 'Prénom', value: 'name.first', }, //2
            { text: 'Détachement', value: 'section', }, //3
            { text: 'Titre', value: 'roles.title', }, //4
            { text: 'Email', value: 'contact.email[0]', }, //5
            { text: 'Portable', value: 'contact.phone.mobile', }, //6
            { text: 'Gr. ex. DPS', value: 'exGroup.dps', }, //7
            { text: 'Gr. ex. APR', value: 'exGroup.apr', }, //8
            { text: 'Actions', value: 'actions', align: 'center', sortable: false, filterable: false }, //9
        ],
    }},
    computed: {
        getHeaders() {
            let headers = [];
            this.headerIds.forEach(id => {
                headers.push(this.availHeaders[id]);
            });
            return headers;
        },
        canEdit() {
            return this.$store.state.user.data.hasClaimOrSuper('users_admin');
        }
    },
    created() {
        Object.values(ROLES).forEach(role => {
            this.availHeaders.push(
                { text: role.short, value: `roles.${role.value}`, align: 'center' }
            )
        });
        this.availHeaders.forEach((header, i) => {
            this.headerNames.push({ text: header.text, value: i });
        });
    },
    methods: {
    }
}
</script>

<style>

</style>