<template>
    <v-card>
        <v-card-text class="mb-0 pb-0">
            <v-alert type="warning" class="" text outlined>
                Cette fonction est encore en test. Merci de signaler tout problème immédiatement.
            </v-alert>
        </v-card-text>

        <v-card-text v-show="!done">
            <p>
                Ici vous pouvez importer plusieurs utilisateurs à partir d'un fichier csv.
            </p>
            <p>
                Les colonnes suivantes sont nécessaires :
                <br>
                NIP, Grade, Détachement, Nom, Prénom, Politesse, Email, Natel.
            </p>
            <p>
                Si la colonne "Importer" est présente, seules les personnes avec cette case remplie seront importées 
                (laisser la case vide pour ignorer).
            </p>
            <p>
                Les colonnes suivantes ne sont pas requises mais l'information sera importée sur le profil:
                <br>
                Fixe, Email 2, Groupe ex DPS, Groupe ex APR, Titre
            </p>
        </v-card-text>
        <!-- PHASE 1 -->
        <!-- v-form @submit.prevent="submitForm" -->
        <!-- v-form :disabled="sendLoading" -->
        <v-form v-show="!done"
            ref="formImport"
            @submit.prevent="submitForm"
            :disabled="sendLoading"
        >
            <v-card-text>
                <m-users-admin-dialog-tabs-import-field
                    @change="futurePeople = $event"
                />

                <m-users-admin-dialog-tabs-import-futures 
                    v-if="futurePeople.length"
                    :people="futurePeople"
                />
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                <v-btn color="secondary" @click="$emit('close-dialog')">
                    <span v-if="big">Annuler</span>
                    <v-icon :right="big">mdi-close</v-icon>
                </v-btn>
                <v-btn
                    type="submit"
                    :color="button[btnStatus].color"
                    :loading="sendLoading"
                    :disabled="sendLoading"
                >
                    <span v-if="big">{{ button[btnStatus].text }}</span>
                    <v-icon :right="big">{{ button[btnStatus].icon }}</v-icon>
                </v-btn>
            </v-card-actions>
        </v-form>

        <!-- PHASE 2 -->
        <v-card-text v-show="done">
            <v-alert type="success" text>
                <p>
                    Les utilisateurs suivants ont été crées avec succès :
                </p>
                <m-users-admin-dialog-tabs-import-created 
                    :people="dbPeople"
                />
            </v-alert>
            <v-btn
                color="success"
                block
                @click="processDone()"
            >
                Terminer
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
const functions = getFunctions(getApp(), 'europe-west1');
const importUsers = httpsCallable(functions, 'user-importUsers');
import mUsersAdminDialogTabsImportFutures from './adminDialogTabsImportFutures.vue';
import mUsersAdminDialogTabsImportField from './adminDialogTabsImportField.vue';
import mUsersAdminDialogTabsImportCreated from './adminDialogTabsImportCreated.vue';

export default {
    name: 'mUsersAdminDialogTabsImport',
    components: {
        mUsersAdminDialogTabsImportFutures,
        mUsersAdminDialogTabsImportField,
        mUsersAdminDialogTabsImportCreated,
    },
    data() { return {
        futurePeople: [],
        dbPeople: [],
        done: false,
        sendLoading: false,
        btnStatus: 'default',
        button: {
            default: { color: 'primary', icon: 'mdi-content-save', text: 'Enregistrer' },
            success: { color: 'success', icon: 'mdi-check', text: 'Enregistré' },
            failure: { color: 'error', icon: 'mdi-alert', text: 'Echec' },
        },
    }},
    computed: {
        big() {
            return !this.$vuetify.breakpoint.xsOnly;
        },
    },
    methods: {
        submitForm() {
            if (this.$refs.formImport.validate()) {
                this.sendLoading = true;
                importUsers({users: this.futurePeople}).then(res => {
                    this.dbPeople = res.data;
                    this.btnStatus = 'success';
                    this.pw = res.data.password;
                    this.done = true;
                }).catch(error => {
                    this.btnStatus = 'failure';
                    this.$store.commit('setError', { type: 'functions', code: error.code, message: error.message });
                }).finally(() => {
                    this.sendLoading = false;
                    setTimeout(() => {this.btnStatus = 'default'}, 3000);
                });
            }
        },
        processDone() {
            this.$emit('close-dialog');
            this.futurePeople = [];
            this.dbPeople = [];
            this.$refs.formImport.reset()
            this.done = false;
        },
    }
}
</script>

<style>

</style>