var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Chargement...","headers":_vm.headers,"items":_vm.users,"sort-by":['name.last', 'name.first'],"multi-sort":true,"items-per-page":20,"locale":"fr-CH","no-data-text":"Aucun résultat","no-results-text":"Aucun résultat correspondant aux filtres","header-props":{
        'sort-by-text': 'Trier par',
    },"footer-props":{
        'items-per-page-all-text': 'Tous',
        'items-per-page-text': 'Lignes par page',
        'items-per-page-options': [5, 10, 20, 50, -1],
    }},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.rank",fn:function(ref){
    var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getRank(value)['med'])+". ")]}},{key:"item.contact.email[0]",fn:function(ref){
    var value = ref.value;
return [_c('a',{staticClass:"table-btn",attrs:{"href":("mailto:" + value)}},[_vm._v(_vm._s(value))])]}},{key:"item.contact.phone.mobile",fn:function(ref){
    var value = ref.value;
return [(!!value)?_c('a',{staticClass:"table-btn",attrs:{"href":("tel:+" + value)}},[_vm._v("+"+_vm._s(value))]):_vm._e()]}},_vm._l((_vm.roles),function(role){return {key:("item.roles." + role),fn:function(ref){
    var value = ref.value;
return [(value)?_c('v-icon',{key:role},[_vm._v("mdi-check")]):_vm._e()]}}}),{key:"item.actions",fn:function(ref){
    var value = ref.value;
return [_c('v-btn',{attrs:{"href":("mailto:" + (value.email)),"icon":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-email")])],1),_c('v-btn',{attrs:{"href":("tel:+" + (value.mobile)),"disabled":!value.mobile,"icon":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-phone")])],1),_c('v-btn',{attrs:{"href":("sms:+" + (value.mobile)),"disabled":!value.mobile,"icon":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-message-processing")])],1),_c('v-btn',{attrs:{"href":("https://wa.me/" + (value.mobile)),"target":"_blank","disabled":!value.mobile,"icon":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-whatsapp")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }